<template>
  <div ref="casesRef" class="cases">
    <el-row class="cases-main">
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${cases.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4">{{$t('message.cases.mainH1')}}</h1>
            <p class="lead">{{$t('message.cases.mainP1')}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="rdn-row">
      <el-col :span="24">
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane v-for="(item, index) in products" :key="index" :label="item.label" :name="item.name">
            <el-card v-for="(item2, index2) in item.info" :key="index2">
              <img :src="item2.img" class="image"/>
              <div style="padding: 14px">
                <span>{{ item2.span }}</span>
                <div class="bottom text-center">
                  <el-button type="danger" class="button" @click="handlePlayVideo(item2)">{{ item2.text }}</el-button>
                </div>
              </div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-model="isVideo" :title="currentTitle" :width="isMobile ? '80%' : '60%'" center @close="handleVideoClose" @close-auto-focus="handleVideoClose">
    <iframe :src="currentVideo"
            scrolling="no"
            border="0"
            frameborder="no"
            framespacing="0"
            allowfullscreen="true"
            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"
    ></iframe>
  </el-dialog>
</template>

<script>
export default {
  name: "RadiantCases",
  components: {},
  computed: {
    products() {
      return [
        {
          label: this.$t('message.cases.rdn71'),
          name: "RDN71",
          info: [
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn71/新疆光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=112889843157547&bvid=BV1PZiPeTEMZ&cid=500001635398559&p=1",
              span: this.$t('message.cases.rdn71S1'),
              text: this.$t('message.cases.play')
            },
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn71/宁夏光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=112889960598802&bvid=BV1o3iNe4E7J&cid=500001635424883&p=1",
              span: this.$t('message.cases.rdn71S2'),
              text: this.$t('message.cases.play')
            }
          ]
        },
        {
          label: this.$t('message.cases.rdn22'),
          name: "RDN22",
          info: [
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn22/瑞得恩屋顶光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=1251211613&bvid=BV1wJ4m1v7s5&cid=1454144796&p=1",
              span: this.$t('message.cases.rdn22S1'),
              text: this.$t('message.cases.play')
            },
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn22/屋顶光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=1301046404&bvid=BV1hu4m1c71o&cid=1453452820&p=1",
              span: this.$t('message.cases.rdn22S2'),
              text: this.$t('message.cases.play')
            },
          ]
        },
        {
          label: this.$t('message.cases.rdn2112'),
          name: "RDN21&RDN12",
          info: [
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn2112/南通屋顶光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=1651160097&bvid=BV1fj421S7Hn&cid=1453420683&p=1",
              span: this.$t('message.cases.rdn2112S1'),
              text: this.$t('message.cases.play')
            },
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn2112/瑞得恩屋顶光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=825440752&bvid=BV1Cg4y177mU&cid=1110805227&p=1",
              span: this.$t('message.cases.rdn2112S2'),
              text: this.$t('message.cases.play')
            },
          ]
        },
        {
          label: this.$t('message.cases.rdn51'),
          name: "RDN51",
          info: []
        },
        {
          label: this.$t('message.cases.rdn31'),
          name: "RDN31",
          info: [
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn31/日本光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=1801141170&bvid=BV13t42187P8&cid=1453412855&p=1",
              span: this.$t('message.cases.rdn31S1'),
              text: this.$t('message.cases.play')
            },
            {
              img: 'https://pvcleaning.radiantpv.com/image/cases/rdn31/昆山屋顶光伏电站清扫现场.png',
              video: "//player.bilibili.com/player.html?isOutside=true&aid=1751174392&bvid=BV11x42117yB&cid=1453407910&p=1",
              span: this.$t('message.cases.rdn31S2'),
              text: this.$t('message.cases.play')
            },
          ]
        },
      ]
    }
  },
  data() {
    return {
      isMobile: false,
      cases: {
        bg1Img: 'https://pvcleaning.radiantpv.com/image/cases/bg.png',
      },
      isVideo: false,
      currentTitle: undefined,
      currentVideo: undefined,
      activeName: "RDN71",
    };
  },
  watch:{
    isWinChange(n) {
      this.isMobile = n;
    }
  },
  created() {
    this.isMobile = this.$store.state.isWinChange;
  },
  methods: {
    handlePlayVideo(info) {
      this.currentTitle = info.span;
      this.currentVideo = info.video;
      this.isVideo = true;
    },
    handleVideoClose() {
      this.currentVideo = undefined;
    }
  }
};
</script>

<style scoped>
.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

iframe {
  width: 100%;
  height: 627px;
}

.rdn-row {
  padding: 100px 0;
  width: 80%;
  margin: 0 auto;
}

.rdn-row .col {
  padding-bottom: 20px;
  text-align: center;
}

.rdn-row .bottom {
  position: relative;
  top: 13px;
}

.rdn-row .image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.el-card {
  width: 22%;
  float: left;
  margin: 10px 10px;
  text-align: center;
}

@media screen and (max-width:768px) {
  .display-4 {
    font-size: 24px;
  }

  .lead {
    font-size: 14px;
  }

  .el-card {
    width: 93%;
  }

  iframe {
    height: 200px;
  }
}
</style>