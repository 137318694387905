<template>
  <div ref="productsRef" class="products">
    <el-row class="products-main">
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${products.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4">{{$t('message.products.mainH1')}}</h1>
            <p class="lead">{{$t('message.products.mainP1')}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-for="(item, index) in productList" :key="index">
      <div :id="item.tag" class="rdn-row" :style="{ backgroundImage: `url(${products.bg2Img})` }">
        <el-col :span="24">
          <div class="wrap1500">
            <div class="col">
              <div class="t2">
                <h1>{{ item.title }}</h1>
                <h2><el-tag type="danger" size="large">{{ item.tag }}</el-tag></h2>
              </div>
            </div>
          </div>
        </el-col>
        <el-row>
          <el-col :span="12" v-if="index%2==0">
            <div class="demo-image__lazy" @mouseenter="handleStartScroll" @mouseleave="handleStopScroll">
              <el-image v-for="url in item.urls" :key="url" :src="url" lazy />
            </div>
          </el-col>
          <el-col :span="12">
            <el-card class="rdn-card">
              <template #header>
                <div style="text-align: center; font-size: 22px; font-weight: bold">
                  <span>{{ item.contents.a }}</span>
                </div>
              </template>
              <div class="advantages" v-for="(item, index) in item.contents.b" :key="index">
                <span class="b">{{ item.b1 }}</span>
                <div class="bottom">
                  <el-tag type="info" size="large" round>{{ item.b2 }}</el-tag>
                </div>
              </div>
              <template #footer>
                <div style="text-align: center;" v-if="item.tag=='RDN71'">
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN71', '')">清扫机器人说明书</el-button></div>
                  <br/>
                </div>
                <div style="text-align: center;" v-if="item.tag=='RDN22'">
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN22', '')">清扫机器人说明书</el-button></div>
                  <br/>
                </div>
                <div style="text-align: center;" v-if="item.tag=='RDN12'">
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN12', '')">清扫机器人说明书</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN12', 'Android')">安卓应用说明书</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN12', 'Apple')">苹果应用说明书</el-button></div>
                  <br/>
                </div>
                <div style="text-align: center;" v-if="item.tag=='RDN21'">
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN21', '')">清扫机器人说明书</el-button></div>
                  <br/>
                </div>
                <div style="text-align: center;" v-if="item.tag=='RDN51'">
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN51', '')">智能观测站说明书</el-button></div>
                  <br/>
                </div>
                <div style="text-align: center;" v-if="item.tag=='RDN31'">
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN31', 'Hand')">手持工具设备说明书</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN31', 'HandZ')">手持工具设备组装</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN31', 'HandC')">手持工具设备操作</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN31', 'Water')">手持工具水泵说明书</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN31', 'WaterJ')">手持工具水泵水源接入</el-button></div>
                  <br/>
                  <div><el-button type="danger" class="button" @click="handlePdf('RDN31', 'Packet')">手持工具包类使用</el-button></div>
                  <br/>
                </div>
              </template>
            </el-card>
          </el-col>
          <el-col :span="12" v-if="index%2!=0">
            <div class="demo-image__lazy" @mouseenter="handleStartScroll" @mouseleave="handleStopScroll">
              <el-image v-for="url in item.urls" :key="url" :src="url" lazy />
            </div>
          </el-col>
        </el-row>
      </div>
    </el-row>
  </div>

  <el-dialog v-model="showPdf" :width="isMobile ? '80%' : '60%'" center @close="handlePdfClose" @close-auto-focus="handlePdfClose">
    <embed :src="pdfUrl" width="100%" height="600px" type="application/pdf" />
  </el-dialog>

  <el-dialog v-model="isVideo" :title="currentTitle" :width="isMobile ? '80%' : '60%'" center @close="handleVideoClose" @close-auto-focus="handleVideoClose">
    <iframe :src="currentVideo"
            scrolling="no"
            border="0"
            frameborder="no"
            framespacing="0"
            allowfullscreen="true"
            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"
    ></iframe>
  </el-dialog>
</template>

<script>
export default {
  name: "RadiantCases",
  components: {},
  computed: {
    productList() {
      return [
        {
          title: this.$t('message.products.rdn71'),
          tag: "RDN71",
          height: "",
          urls: [
            'https://pvcleaning.radiantpv.com/image/products/rdn71/1.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn71/2.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn71/3.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn71/4.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn71/5.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn71/6.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn71/7.png',
          ],
          contents: {
            a: this.$t('message.products.advantage'),
            b: [
              { b1: this.$t('message.products.rdn71b11'), b2: this.$t('message.products.rdn71b21') },
              { b1: this.$t('message.products.rdn71b12'), b2: this.$t('message.products.rdn71b22') },
              { b1: this.$t('message.products.rdn71b13'), b2: this.$t('message.products.rdn71b23') },
              { b1: this.$t('message.products.rdn71b14'), b2: this.$t('message.products.rdn71b24') },
              { b1: this.$t('message.products.rdn71b15'), b2: this.$t('message.products.rdn71b25') },
              { b1: this.$t('message.products.rdn71b16'), b2: this.$t('message.products.rdn71b26') },
            ]
          }
        },
        {
          title: this.$t('message.products.rdn22'),
          tag: "RDN22",
          urls: [
            'https://pvcleaning.radiantpv.com/image/products/rdn22/1.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn22/2.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn22/3.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn22/4.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn22/5.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn22/6.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn22/7.png',
          ],
          contents: {
            a: this.$t('message.products.advantage'),
            b: [
              { b1: this.$t('message.products.rdn22b11'), b2: this.$t('message.products.rdn22b21') },
              { b1: this.$t('message.products.rdn22b12'), b2: this.$t('message.products.rdn22b22') },
              { b1: this.$t('message.products.rdn22b13'), b2: this.$t('message.products.rdn22b23') },
              { b1: this.$t('message.products.rdn22b14'), b2: this.$t('message.products.rdn22b24') },
              //{ b1: this.$t('message.products.rdn22b15'), b2: this.$t('message.products.rdn22b25') },
              //{ b1: this.$t('message.products.rdn22b16'), b2: this.$t('message.products.rdn22b26') },
            ]
          }
        },
        {
          title: this.$t('message.products.rdn12'),
          tag: "RDN12",
          urls: [
            'https://pvcleaning.radiantpv.com/image/products/rdn12/1.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn12/2.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn12/3.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn12/4.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn12/5.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn12/6.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn12/7.png',
          ],
          contents: {
            a: this.$t('message.products.advantage'),
            b: [
              { b1: this.$t('message.products.rdn12b11'), b2: this.$t('message.products.rdn12b21') },
              { b1: this.$t('message.products.rdn12b12'), b2: this.$t('message.products.rdn12b22') },
              { b1: this.$t('message.products.rdn12b13'), b2: this.$t('message.products.rdn12b23') },
              { b1: this.$t('message.products.rdn12b14'), b2: this.$t('message.products.rdn12b24') },
              { b1: this.$t('message.products.rdn12b15'), b2: this.$t('message.products.rdn12b25') },
              { b1: this.$t('message.products.rdn12b16'), b2: this.$t('message.products.rdn12b26') },
            ]
          }
        },
        /*
        {
          title: this.$t('message.products.rdn21'),
          tag: "RDN21",
          urls: [
            https://pvcleaning.radiantpv.com/image/products/rdn21/1.png',
            https://pvcleaning.radiantpv.com/image/products/rdn21/2.png',
            https://pvcleaning.radiantpv.com/image/products/rdn21/3.png',
            https://pvcleaning.radiantpv.com/image/products/rdn21/4.png',
            https://pvcleaning.radiantpv.com/image/products/rdn21/5.png',
            https://pvcleaning.radiantpv.com/image/products/rdn21/6.png',
            https://pvcleaning.radiantpv.com/image/products/rdn21/7.png',
          ],
          contents: {
            a: this.$t('message.products.advantage'),
            b: [
              { b1: this.$t('message.products.rdn21b11'), b2: this.$t('message.products.rdn21b21') },
              { b1: this.$t('message.products.rdn21b12'), b2: this.$t('message.products.rdn21b22') },
              { b1: this.$t('message.products.rdn21b13'), b2: this.$t('message.products.rdn21b23') },
              { b1: this.$t('message.products.rdn21b14'), b2: this.$t('message.products.rdn21b24') },
              { b1: this.$t('message.products.rdn21b15'), b2: this.$t('message.products.rdn21b25') },
              { b1: this.$t('message.products.rdn21b16'), b2: this.$t('message.products.rdn21b26') },
            ]
          }
        },
        */
        {
          title: this.$t('message.products.rdn51'),
          tag: "RDN51",
          urls: [
            'https://pvcleaning.radiantpv.com/image/products/rdn51/1.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn51/2.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn51/3.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn51/4.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn51/5.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn51/6.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn51/7.png',
          ],
          contents: {
            a: this.$t('message.products.advantage'),
            b: [
              { b1: this.$t('message.products.rdn51b11'), b2: this.$t('message.products.rdn51b21') },
              { b1: this.$t('message.products.rdn51b12'), b2: this.$t('message.products.rdn51b22') },
              { b1: this.$t('message.products.rdn51b13'), b2: this.$t('message.products.rdn51b23') },
              { b1: this.$t('message.products.rdn51b14'), b2: this.$t('message.products.rdn51b24') },
             //{ b1: this.$t('message.products.rdn51b15'), b2: this.$t('message.products.rdn51b25') },
             //{ b1: this.$t('message.products.rdn51b16'), b2: this.$t('message.products.rdn51b26') },
            ]
          }
        },
        {
          title: this.$t('message.products.rdn31'),
          tag: "RDN31",
          urls: [
            'https://pvcleaning.radiantpv.com/image/products/rdn31/1.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn31/2.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn31/3.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn31/4.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn31/5.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn31/6.png',
            'https://pvcleaning.radiantpv.com/image/products/rdn31/7.png',
          ],
          contents: {
            a: this.$t('message.products.advantage'),
            b: [
              { b1: this.$t('message.products.rdn31b11'), b2: this.$t('message.products.rdn31b21') },
              { b1: this.$t('message.products.rdn31b12'), b2: this.$t('message.products.rdn31b22') },
              { b1: this.$t('message.products.rdn31b13'), b2: this.$t('message.products.rdn31b23') },
              { b1: this.$t('message.products.rdn31b14'), b2: this.$t('message.products.rdn31b24') },
              { b1: this.$t('message.products.rdn31b15'), b2: this.$t('message.products.rdn31b25') },
              { b1: this.$t('message.products.rdn31b16'), b2: this.$t('message.products.rdn31b26') },
            ]
          }
        },
      ]
    }
  },
  data() {
    return {
      isVideo: false,
      currentTitle: undefined,
      currentVideo: undefined,
      isMobile: false,
      showPdf: false,
      pdfUrl: 'https://pvcleaning.radiantpv.com/image/products/rdn71/轨道机器人说明书.pdf',
      scrollInterval: null,
      products: {
        bg1Img: 'https://pvcleaning.radiantpv.com/image/products/bg.jpg',
        bg2Img: 'https://pvcleaning.radiantpv.com/image/products/win.jpg',
        //rdn71: 'https://pvcleaning.radiantpv.com/image/products/RDN71.png',
        //rdn12: 'https://pvcleaning.radiantpv.com/image/products/RDN12.png',
        //rdn21: 'https://pvcleaning.radiantpv.com/image/products/RDN21.png',
        //rdn22: 'https://pvcleaning.radiantpv.com/image/products/RDN22.png',
        //rdn51: 'https://pvcleaning.radiantpv.com/image/products/RDN51.png',
        //rdn31: 'https://pvcleaning.radiantpv.com/image/products/RDN31.png',
      }
    };
  },
  watch:{
    isWinChange(n) {
      this.isMobile = n;
    }
  },
  created() {
    this.isMobile = this.$store.state.isWinChange;
  },
  methods: {
    handleStartScroll(event) {
      const container = event.currentTarget;
      const speed = 1; // 调整滚动速度
      this.scrollInterval = setInterval(() => {
        container.scrollTop += speed;
        if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
          container.scrollTop = 0; // 回到顶部
        }
      }, 10); // 调整滚动频率
    },
    handleStopScroll(event) {
      const container = event.currentTarget;
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
      container.scrollTop = 0; // 回到顶部
    },
    previewPdf() {
    },
    handlePdf(p, t) {
      const locale = this.$i18n.locale;
      if ( p == "RDN71" ) {
        this.pdfUrl = 'https://pvcleaning.radiantpv.com/image/products/rdn71/robot-' + locale + '.pdf';
        this.showPdf = true;
      } else if ( p == "RDN22" ) {
        console.log("RDN22 UNDEFINED");
      } else if ( p == "RDN12" ) {
        if ( t == 'Android' ) {
          this.pdfUrl = 'https://pvcleaning.radiantpv.com/image/products/rdn12/android-app-' + locale + '.pdf';
        } else if ( t == 'Apple' ) {
          this.pdfUrl = 'https://pvcleaning.radiantpv.com/image/products/rdn12/apple-app-' + locale + '.pdf';
        } else {
          this.pdfUrl = 'https://pvcleaning.radiantpv.com/image/products/rdn12/robot-' + locale + '.pdf';
        }
        this.showPdf = true;
      } else if ( p == "RDN21" ) {
        console.log("RDN21 UNDEFINED");
      } else if ( p == "RDN51" ) {
        console.log("RDN51 UNDEFINED");
      } else if ( p == "RDN31" ) {
        if ( t == 'Hand' ) {
          this.pdfUrl = 'https://pvcleaning.radiantpv.com/image/products/rdn31/hand-' + locale + '.pdf';
          this.showPdf = true;
        } else if ( t == 'Water' ) {
          this.pdfUrl = 'https://pvcleaning.radiantpv.com/image/products/rdn31/water-' + locale + '.pdf';
          this.showPdf = true;
        } else if ( t == 'HandZ') {
          this.currentVideo = "//player.bilibili.com/player.html?isOutside=true&aid=112906955983022&bvid=BV1KDaRegEei&cid=500001638666615&p=1";
          this.isVideo = true;
          this.currentTitle = "手持清扫工具设备组装";
        } else if ( t == 'HandC' ) {
          this.currentVideo = "//player.bilibili.com/player.html?isOutside=true&aid=112906956049810&bvid=BV1xDaRegEUa&cid=500001638665909&p=1";
          this.isVideo = true;
          this.currentTitle = "手持清扫工具设备操作";
        } else if ( t == 'WaterJ' ) {
          this.currentVideo = "//player.bilibili.com/player.html?isOutside=true&aid=112906955917515&bvid=BV12DaReuEAd&cid=500001638667099&p=1";
          this.isVideo = true;
          this.currentTitle = "手持清扫工具水泵水源接入";
        } else if ( t == 'Packet' ) {
          this.currentVideo = "//player.bilibili.com/player.html?isOutside=true&aid=112906956049256&bvid=BV14DaRegE7D&cid=500001638664779&p=1";
          this.isVideo = true;
          this.currentTitle = "手持清扫工具包类的使用";
        }
      }
    },
    handlePdfClose() {
      this.showPdf = false;
    },
    handleVideoClose() {
      this.currentVideo = undefined;
    }
  }
};
</script>

<style scoped>
.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

.rdn-row {
  padding: 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.rdn-row .col {
  padding-bottom: 20px;
  text-align: center;
}

.rdn-card {
  height: 100%;
  width: 100%;
}

.demo-image__lazy {
  width: 100%;
  height: calc(1400px - 1vw);
  overflow-y: hidden;
  transition: height 0.5s ease; /* 平滑过渡 */
}

.demo-image__lazy .el-image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}

.demo-image__lazy .el-image:last-child {
  margin-bottom: 0;
}

.advantages {
  margin: 0px 20px;
}

.advantages .b {
  height: 80px;
  line-height: 80px;
  font-size: 22px;
}

.advantages .bottom {
  margin-bottom: 20px;
}

.advantages .bottom span {
  height: 50px;
  font-size: 18px;
  white-space: pre-wrap; /* 保持空格和换行 */
  overflow-wrap: break-word; /* 允许长单词换行 */
}

iframe {
  width: 100%;
  height: 627px;
}

@media screen and (max-width:1080px) {
  .advantages .bottom span {
    font-size: 16px;
    height: 50px;
  }
}

@media screen and (max-width:768px) {
  .display-4 {
    font-size: 24px;
  }

  .lead {
    font-size: 14px;
  }

  .advantages {
    margin: 0px auto;
  }

  .advantages .b {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
  }

  .advantages .bottom span {
    font-size: 12px;
    height: 80px;
  }

  .rdn-row .col .t2 h1 {
    font-size: 28px;
  }

  .rdn-row .col .t2 h2 {
    font-size: 22px;
  }

  .rdn-card span {
    font-size: 16px;
  }

  iframe {
    height: 200px;
  }
}
</style>